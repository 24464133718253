// src/app/services/phrase.service.ts
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

export interface Phrase {
  id: number;
  text: string;
}

@Injectable({
  providedIn: "root",
})
export class PhraseService {
  private apiUrl = "api/bingoPhrases";

  constructor(private http: HttpClient) {}

  getPhrases(): Observable<Phrase[]> {
    return this.http.get<Phrase[]>(this.apiUrl);
  }

  getPhrase(id: number): Observable<Phrase> {
    return this.http.get<Phrase>(`${this.apiUrl}/${id}`);
  }

  createPhrase(phrase: Phrase): Observable<Phrase> {
    return this.http.post<Phrase>(this.apiUrl, phrase);
  }

  updatePhrase(phrase: Phrase): Observable<void> {
    return this.http.put<void>(`${this.apiUrl}/${phrase.id}`, phrase);
  }

  deletePhrase(id: number): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/${id}`);
  }
}
