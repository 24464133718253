import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BingoCellComponent } from "./components/bingo-cell.component";
import { BingoService } from "./services/bingo.service";
import { SignalRService } from "./services/signalr.service";
import { PhrasesComponent } from "./components/phrases.component";
import { BingoBoardComponent } from "./components/bingo-board.component";

@NgModule({
  declarations: [BingoBoardComponent, BingoCellComponent],
  imports: [CommonModule, PhrasesComponent],
  providers: [BingoService, SignalRService],
  exports: [BingoBoardComponent, PhrasesComponent],
})
export class BingoModule {}
