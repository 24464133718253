import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { BingoBoard } from "../interfaces/bingo-board.interface";
import { BingoCell } from "../interfaces/bingo-cell.interface";

@Injectable({
  providedIn: "root",
})
export class BingoService {
  private currentBoard = new BehaviorSubject<BingoBoard | null>(null);

  constructor(private http: HttpClient) {}

  generateBoard(): Observable<BingoBoard> {
    return this.http.get<BingoBoard>("/api/bingo/board");
  }

  updateCell(cell: BingoCell): Observable<BingoCell> {
    return this.http.put<BingoCell>(`/api/bingo/cell/${cell.id}`, cell);
  }

  getCurrentBoard(): Observable<BingoBoard | null> {
    return this.currentBoard.asObservable();
  }

  setCurrentBoard(board: BingoBoard) {
    this.currentBoard.next(board);
  }
}
