import { Component, OnInit, OnDestroy } from "@angular/core";
import { BingoService } from "../services/bingo.service";
import { SignalRService } from "../services/signalr.service";
import { BingoBoard } from "../interfaces/bingo-board.interface";
import { Subscription } from "rxjs";
import { BingoCell } from "../interfaces/bingo-cell.interface";

@Component({
  selector: "app-bingo-board",
  templateUrl: "./bingo-board.component.html",
  styleUrls: ["./bingo-board.component.scss"],
})
export class BingoBoardComponent implements OnInit, OnDestroy {
  board: BingoBoard | null = null;
  private subscriptions: Subscription[] = [];

  constructor(
    private bingoService: BingoService,
    private signalRService: SignalRService
  ) {}

  ngOnInit() {
    this.loadBoard();
    this.subscribeToUpdates();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  private loadBoard() {
    this.bingoService.generateBoard().subscribe((board) => {
      // Modify the center cell (index 12) to be a free space
      if (board && board.cells.length > 12) {
        board.cells[12] = {
          ...board.cells[12],
          phrase: "FREE",
          isMarked: true,
          isFreeSpace: true,
          markedBy: "System",
          markedAt: new Date(),
        };
      }
      this.board = board;
      this.bingoService.setCurrentBoard(board);
    });
  }

  private subscribeToUpdates() {
    this.subscriptions.push(
      this.signalRService.cellUpdates.subscribe((updatedCell) => {
        if (updatedCell && this.board) {
          const cellIndex = this.board.cells.findIndex(
            (c) => c.id === updatedCell.id
          );
          if (cellIndex !== -1 && cellIndex !== 12) {
            // Prevent updates to free space
            this.board.cells[cellIndex] = updatedCell;
          }
        }
      })
    );
  }

  async onCellClick(cell: BingoCell) {
    // Prevent clicking on the free space
    if (cell.isFreeSpace) {
      return;
    }

    const updatedCell = {
      ...cell,
      isMarked: !cell.isMarked,
      markedBy: cell.isMarked ? undefined : "Current User", // Replace with actual user info
      markedAt: cell.isMarked ? undefined : new Date(),
    };

    try {
      await this.bingoService.updateCell(updatedCell).toPromise();
    } catch (error) {
      console.error("Error updating cell:", error);
    }
  }
}
