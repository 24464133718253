// components/bingo-cell.component.ts
import { Component, Input, Output, EventEmitter } from "@angular/core";
import { BingoCell } from "../interfaces/bingo-cell.interface";

@Component({
  selector: "app-bingo-cell",
  template: `
    <div
      class="bingo-cell"
      [class.marked]="cell.isMarked"
      [attr.title]="cell.markedBy"
      (click)="onClick()"
    >
      <div class="phrase">{{ cell.phrase }}</div>
    </div>
  `,
  styles: [
    `
      .bingo-cell {
        aspect-ratio: 1;
        background: linear-gradient(145deg, #ffffff, #f5f5f5);
        border-radius: 10px;
        padding: 10px;
        cursor: pointer;
        transition: all 0.3s ease;
        box-shadow: 5px 5px 10px #d9d9d9, -5px -5px 10px #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        text-align: center;
        overflow: hidden;
      }

      .bingo-cell:hover {
        transform: translateY(-2px);
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
      }

      .bingo-cell.marked {
        background: linear-gradient(145deg, #4caf50, #45a049);
        color: white;
      }

      .cell-content {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-size: 0.9rem;
        line-height: 1.2;
        word-wrap: break-word;
      }

      @media (max-width: 768px) {
        .cell-content {
          font-size: 0.8rem;
        }
      }
    `,
  ],
})
export class BingoCellComponent {
  @Input() cell!: BingoCell;
  @Output() cellClick = new EventEmitter<BingoCell>();

  onClick() {
    this.cellClick.emit(this.cell);
  }
}
