// src/app/components/phrases/phrases.component.ts
import { Component, OnInit } from "@angular/core";
import { PhraseService, Phrase } from "../services/phrase.service";
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { CommonModule } from "@angular/common";

@Component({
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  selector: "app-phrases",
  templateUrl: "./phrases.component.html",
  styleUrls: ["./phrases.component.scss"],
})
export class PhrasesComponent implements OnInit {
  phrases: Phrase[] = [];
  phraseForm: FormGroup;
  editMode = false;
  currentPhraseId: number | null = null;

  constructor(private phraseService: PhraseService, private fb: FormBuilder) {
    this.phraseForm = this.fb.group({
      text: ["", Validators.required],
    });
  }

  ngOnInit(): void {
    this.loadPhrases();
  }

  loadPhrases(): void {
    this.phraseService
      .getPhrases()
      .subscribe((phrases) => (this.phrases = phrases));
  }

  onSubmit(): void {
    if (this.phraseForm.valid) {
      if (this.editMode && this.currentPhraseId) {
        const updatedPhrase: Phrase = {
          id: this.currentPhraseId,
          text: this.phraseForm.value.text,
        };
        this.phraseService.updatePhrase(updatedPhrase).subscribe(() => {
          this.loadPhrases();
          this.resetForm();
        });
      } else {
        this.phraseService.createPhrase(this.phraseForm.value).subscribe(() => {
          this.loadPhrases();
          this.resetForm();
        });
      }
    }
  }

  editPhrase(phrase: Phrase): void {
    this.editMode = true;
    this.currentPhraseId = phrase.id;
    this.phraseForm.patchValue({
      text: phrase.text,
    });
  }

  deletePhrase(id: number): void {
    if (confirm("Are you sure you want to delete this phrase?")) {
      this.phraseService.deletePhrase(id).subscribe(() => {
        this.loadPhrases();
      });
    }
  }

  resetForm(): void {
    this.editMode = false;
    this.currentPhraseId = null;
    this.phraseForm.reset();
  }
}
