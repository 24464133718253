import { Injectable } from "@angular/core";
import * as signalR from "@microsoft/signalr";
import { BehaviorSubject } from "rxjs";
import { BingoCell } from "../interfaces/bingo-cell.interface";

@Injectable({
  providedIn: "root",
})
export class SignalRService {
  private hubConnection: signalR.HubConnection;
  public cellUpdates = new BehaviorSubject<BingoCell | null>(null);

  constructor() {
    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl("/bingohub")
      .configureLogging(signalR.LogLevel.Information)
      .withAutomaticReconnect()
      .build();

    this.startConnection();
    this.addListeners();
  }

  private async startConnection() {
    try {
      await this.hubConnection.start();
      console.log("SignalR Connected!");
    } catch (err) {
      console.error("SignalR Connection Error: ", err);
      setTimeout(() => this.startConnection(), 5000);
    }
  }

  private addListeners() {
    this.hubConnection.on("CellUpdated", (cell: BingoCell) => {
      this.cellUpdates.next(cell);
    });
  }

  public async updateCell(cell: BingoCell) {
    await this.hubConnection.invoke("UpdateCell", cell);
  }
}
