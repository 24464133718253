<div class="bingo-container">
  <div class="bingo-title">
    <span>B</span>
    <span>I</span>
    <span>N</span>
    <span>G</span>
    <span>O</span>
  </div>
  <div class="bingo-board" *ngIf="board">
    <div class="board-grid">
      <app-bingo-cell
        *ngFor="let cell of board.cells; let i = index"
        [cell]="cell"
        [class.free-space]="i === 12"
        (cellClick)="onCellClick($event)"
      >
      </app-bingo-cell>
    </div>
  </div>
</div>
