<!-- src/app/components/phrases/phrases.component.html -->
<div class="phrases-container">
  <h2>Manage Bingo Phrases</h2>

  <form [formGroup]="phraseForm" (ngSubmit)="onSubmit()" class="phrase-form">
    <div class="form-group">
      <input
        type="text"
        formControlName="text"
        placeholder="Enter phrase"
        class="form-control"
      />
    </div>
    <div class="button-group">
      <button type="submit" [disabled]="!phraseForm.valid">
        {{ editMode ? "Update" : "Add" }} Phrase
      </button>
      <button type="button" *ngIf="editMode" (click)="resetForm()">
        Cancel
      </button>
    </div>
  </form>

  <div class="phrases-list">
    <div *ngFor="let phrase of phrases" class="phrase-item">
      <span>{{ phrase.text }}</span>
      <div class="actions">
        <button (click)="editPhrase(phrase)">Edit</button>
        <button (click)="deletePhrase(phrase.id)">Delete</button>
      </div>
    </div>
  </div>
</div>
